<script>
import appConfig from "@/app.config";
import { FormWizard, TabContent } from "vue-form-wizard";
import StoreService from '@/services/store'
import UserService from '@/services/user'

export default {
  page: {
    title: "Seja um Parceiro",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { FormWizard, TabContent },
  data() {
    return {
      data: {
        address: {
          street: '',
          city: '',
          disctrict: '',
          state: '',
        },
      },
      confirmation: false,
    }
  },
  methods: {
    async register() {
      if (!this.data.accept) {
        return this.$toast.warning('Você precisa aceitar os termos de uso');
      }

      let loader = this.$loading.show();

      try {
        await StoreService.registerPartner(this.data);

        loader.hide();

        this.confirmation = true;
      } catch (err) {
        loader.hide();

        if (err.response.data.code && err.response.data.code === 'E9999') {
          this.$toast.error(err.response.data.msg);
        } else {
          this.$toast.error('Ocorreu um erro ao enviar o cadastro');
        }
      }
    },
    beforeTabSwitch1() {
      // if (!this.data.name || !this.data.cnpj || !this.data.phoneNumber || !this.data.email || !this.data.contactName) {
      //   return this.$toast.warning('Preencha todos os campos');
      // }
      return true;
    },
    beforeTabSwitch2() {
      if (!this.data.name || !this.data.cnpj || !this.data.phoneNumber || !this.data.email || !this.data.contactName) {
        return this.$toast.warning('Preencha todos os campos');
      }
      return true;
    },
    beforeTabSwitch3() {
      if (!this.data.password) {
        this.$toast.warning('Informe a senha de acesso');

        return false;
      }
      return true;
    },
    async findAddress() {
      if (this.data.address.zipcode) {
        try {
          const result = await UserService.getAddressByZipcode(this.data.address.zipcode);

          if (result.data) {
            setTimeout(() => {
                this.data.address.district = result.data.bairro;
                this.data.address.city = result.data.localidade;
                this.data.address.street = result.data.logradouro;
                this.data.address.state = result.data.uf;
            }, 100);
          }
        } catch (err) {
          // loader.hide();
        }
      }
    }
  },
  mounted() {
    this.findAddress()
  }
};
</script>

<template>
  <div>
    <div class="account-pages my-5 pt-5">
      <div class="container">
        <div class="row img-login" v-if="!confirmation">
          <img src="@/assets/images/logo-whey.png" alt class="img-fluid" />
        </div>

        <div class="row justify-content-center">
          <div class="col-md-10 col-lg-10 col-xl-8">
            <div class="card overflow-hidden">
              <div class="card-body confirmation" v-if="confirmation">
                <div class="row img-login">
                  <img src="@/assets/images/logo-whey.png" alt class="img-fluid" />
                </div>
                <i class="bx bx bx-check-circle mr-2 text-success"></i>
                <p class="title">Sua solicitação de cadastro foi enviada com sucesso!</p>
                <p>Vamos analisar as informações e entramos em contato em caso de dúvidas</p>

                <b-button variant="link" href="https://whey.app">Ir para o site</b-button>
              </div>

              <div class="card-body" v-if="!confirmation">
                <!-- <div class="bg-default">
                  <div class="row img-login">
                    <img src="@/assets/images/logo-whey.png" alt class="img-fluid" />
                  </div>
                </div> -->

                <h4 class="card-title">Seja um Parceiro Whey!</h4>
                <!-- <p class="card-title-desc">Preencha o pré-cadastro para solicitar a avaliação da sua loja como novo parceiro Whey. Entraremos em contato assim que possível.</p> -->

                <form-wizard
                  shape="tab"
                  color="#e0103d"
                  back-button-text="Voltar"
                  next-button-text="Continuar"
                  finish-button-text="ENVIAR CADASTRO"
                  @on-complete="register"
                >
                  <tab-content icon="mdi mdi-office-building" :before-change="beforeTabSwitch1">
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group row mb-3">
                          <label class="col-md-3 col-form-label" for="name">Razão Social</label>
                          <div class="col-md-9">
                            <input id="name" type="text" class="form-control" v-model="data.name" />
                          </div>
                        </div>
                        <div class="form-group row mb-3">
                          <label class="col-md-3 col-form-label" for="cnpj">CNPJ</label>
                          <div class="col-md-9">
                            <input
                              id="cnpj"
                              type="tel"
                              name="cnpj"
                              class="form-control"
                              v-model="data.cnpj"
                              v-mask="'##.###.###/####-##'"
                            />
                          </div>
                        </div>

                        <div class="form-group row mb-3">
                          <label class="col-md-3 col-form-label" for="phoneNumber">Telefone</label>
                          <div class="col-md-9">
                            <input
                              id="phoneNumber"
                              type="tel"
                              class="form-control"
                              v-model="data.phoneNumber"
                              v-mask="'(##) #####-####'"
                            />
                          </div>
                        </div>

                        <div class="form-group row mb-3">
                          <label class="col-md-3 col-form-label" for="email">E-mail</label>
                          <div class="col-md-9">
                            <input id="email" type="text" class="form-control" v-model="data.email" />
                          </div>
                        </div>

                        <div class="form-group row mb-3">
                          <label class="col-md-3 col-form-label" for="contactName">Nome para Contato</label>
                          <div class="col-md-9">
                            <input id="text" type="text" class="form-control" v-model="data.contactName" />
                          </div>
                        </div>
                      </div>
                      <!-- end col -->
                    </div>
                    <!-- end row -->
                  </tab-content>
                  <tab-content icon="mdi mdi-map-marker">
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group row mb-3">
                          <label class="col-md-3 col-form-label" for="zipcode">CEP</label>
                          <div class="col-md-3">
                            <input
                              type="tel"
                              name="zipcode"
                              class="form-control"
                              v-model="data.address.zipcode"
                              v-mask="'#####-###'"
                              @blur="findAddress"
                            />
                          </div>
                        </div>
                        <div class="form-group row mb-3">
                          <label class="col-md-3 col-form-label" for="street">Endereço</label>
                          <div class="col-md-9">
                            <input type="tel" name="street" class="form-control" v-model="data.address.street" />
                          </div>
                        </div>
                        
                        <div class="form-group row mb-3">
                          <label class="col-md-3 col-form-label" for="number">Número</label>
                          <div class="col-md-2">
                            <input type="tel" name="number" class="form-control" v-model="data.address.number" />
                          </div>
                        </div>

                        <div class="form-group row mb-3">
                          <label class="col-md-3 col-form-label" for="number">Bairro</label>
                          <div class="col-md-9">
                            <input type="tel" name="number" class="form-control" v-model="data.address.district" />
                          </div>
                        </div>

                        <div class="form-group row mb-3">
                          <label class="col-md-3 col-form-label" for="number">Cidade</label>
                          <div class="col-md-9">
                            <input type="tel" name="number" class="form-control" v-model="data.address.city" />
                          </div>
                        </div>

                        <div class="form-group row mb-3">
                          <label class="col-md-3 col-form-label" for="number">Estado</label>
                          <div class="col-md-4">
                            <!-- <input type="tel" name="number" class="form-control" v-model="data.address.state" /> -->
                            <select class="custom-select" v-model="data.address.state">
                              <option value="AC">Acre</option>
                              <option value="AL">Alagoas</option>
                              <option value="AP">Amapá</option>
                              <option value="AM">Amazonas</option>
                              <option value="BA">Bahia</option>
                              <option value="CE">Ceará</option>
                              <option value="DF">Distrito Federal</option>
                              <option value="ES">Espírito Santo</option>
                              <option value="GO">Goiás</option>
                              <option value="MA">Maranhão</option>
                              <option value="MT">Mato Grosso</option>
                              <option value="MS">Mato Grosso do Sul</option>
                              <option value="MG">Minas Gerais</option>
                              <option value="PA">Pará</option>
                              <option value="PB">Paraíba</option>
                              <option value="PR">Paraná</option>
                              <option value="PE">Pernambuco</option>
                              <option value="PI">Piauí</option>
                              <option value="RJ">Rio de Janeiro</option>
                              <option value="RN">Rio Grande do Norte</option>
                              <option value="RS">Rio Grande do Sul</option>
                              <option value="RO">Rondônia</option>
                              <option value="RR">Roraima</option>
                              <option value="SC">Santa Catarina</option>
                              <option value="SP">São Paulo</option>
                              <option value="SE">Sergipe</option>
                              <option value="TO">Tocantins</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <!-- end col -->
                    </div>
                    <!-- end row -->
                  </tab-content>
                  <tab-content icon="mdi mdi-account-lock" :before-change="beforeTabSwitch3">
                    <div class="row">
                      <div class="col-12">
                        <!-- <div class="form-group row mb-3">
                          <label class="col-md-3 col-form-label" for="name">Nome de Usuário</label>
                          <div class="col-md-5">
                            <input id="username" type="text" class="form-control" v-model="data.username" placeholder="Seu login para acesso da plataforma" />
                          </div>
                        </div> -->
                        <div class="form-group row mb-3">
                          <label class="col-md-3 col-form-label" for="cnpj">Senha de Acesso</label>
                          <div class="col-md-6">
                            <input id="password" type="password" class="form-control" v-model="data.password" placeholder="Defina sua senha" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end row -->
                  </tab-content>
                  <tab-content icon="mdi mdi-checkbox-marked-circle">
                    <div class="row">
                      <div class="col-12">
                        <div class="text-center">
                          <h3 class="mt-0">Quase lá!</h3>

                          <p class="w-75 mb-2 mx-auto">
                            Antes de confirmar o seu cadastro, verifique os termos de uso.
                          </p>

                          <div class="mb-3 mt-3 terms">
                            <div class="custom-control custom-checkbox">
                              <input id="customCheck1" type="checkbox" class="custom-control-input" v-model="data.accept" />
                              <label
                                class="custom-control-label"
                                for="customCheck1"
                              ></label>
                            </div>
                            <p>Concordo com os <span v-b-modal.modal-terms>termos e condições de uso</span></p>
                          </div>
                        </div>
                      </div>
                      <!-- end col -->
                    </div>
                    <!-- end row -->
                  </tab-content>
                </form-wizard>

                <!-- <div class="p-2">
                  <form class="form-horizontal" @submit.prevent="register">
                    <div class="form-group">
                      <label for="password">Nova senha</label>
                      <input
                        type="password"
                        class="form-control"
                        id="password"
                        v-model="password"
                        placeholder="Digite sua senha"
                      />
                    </div>

                    <div class="form-group">
                      <label for="passwordConfirmation">Confirmar senha</label>
                      <input
                        type="password"
                        class="form-control"
                        id="passwordConfirmation"
                        v-model="passwordConfirmation"
                        placeholder="Digite a senha novamente"
                      />
                    </div>

                    <div class="form-group row mb-0">
                      <div class="col-12 text-right">
                        <button class="btn btn-primary w-md" type="submit">Confirmar Senha</button>
                      </div>
                    </div>
                  </form>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-terms"
      scrollable
      size="lg"
      title="Termos e Condições Gerais de Uso"
      title-class="font-18"
      hide-footer
    >
      <p>
        Estes Termos e Condições Gerais (“Termo Geral”) informam as condições gerais de uso dos serviços
        oferecidos por AMERICAN STORE DISTRIBUIÇÃO DE SUPLEMENTOS EIRELI, inscrita no CNPJ nº
        18.744.639/0001-47, com sede na Avenida dos Pequis, n° 126, Jardim Vila Formosa, no Município de
        São Paulo, Estado de São Paulo, CEP 03470-050, doravante denominada WHEY ao denominado
        PARCEIRO, qualificado no Termo de Cadastro.
      </p>
      <p>
        A WHEY e o Parceiro poderão ser aqui referidos como “Parte” ou como “Partes”, quando em conjunto.
        Esse Termo Geral será complementado por propostas comerciais, relacionadas à individualização e
        descrição dos Serviços prestados pela WHEY (“Termo de Serviço” e, quando em conjunto com o Termo
        Geral, “Termos”).
      </p>
      <p class="bold">
        A ACEITAÇÃO DESTE TERMO GERAL E DOS RESPECTIVOS TERMOS DE SERVIÇO É
        ABSOLUTAMENTE INDISPENSÁVEL À UTILIZAÇÃO DOS SERVIÇOS
      </p>

      <p class="clause">I – Objeto</p>
      <p>1.1. Este Termo Geral aplica-se ao uso dos serviços oferecidos pela WHEY e contém informações legais
importantes que nortearão a relação contratual com o Parceiro, e deverão ser observadas em conjunto
com os Termos de Serviço, que trarão as especificidades de cada Serviço contratado.</p>
      <p>1.2. Em caso de conflito entre o Termo Geral e os Termos de Serviço, o Termo de Serviço deverá
prevalecer.</p>
      <p class="clause">II - Cadastro</p>
      <p>2.1. Os Serviços oferecidos pela WHEY poderão ser contratados, mediante envio dos documentos que
estarão listados nos Termos de serviços e que deverão ser aprovados previamente pela WHEY, por
pessoas jurídicas que estejam regularmente constituídas e desde que legalmente representadas,
conforme seus atos constitutivos.</p>
      <p>2.2. O Parceiro acessará sua conta através de apelido (login) e senha e compromete-se a não
informar a terceiros esses dados, responsabilizando-se integralmente pelo uso que deles seja feito.</p>
      <p>2.3. O Parceiro compromete-se a notificar a WHEY imediatamente, e através de meio seguro, a respeito
de qualquer uso não autorizado de sua conta, bem como o acesso nãoautorizado por terceiros a mesma.</p>
      <p>2.4. Exceto no caso de autorização expressa da WHEY em sentido contrário, o Parceiro não poderá, em
nenhuma hipótese, vender, alugar, ou de outra forma transferir a titularidade de sua conta (incluindo-se
qualificações e reputação), respondendo cível e criminalmente pelo descumprimento da persente
disposição.</p>
      <p>2.5. A WHEY deverá ser imediatamente comunicada a respeito de qualquer modificação de controle
societário que venha a ocorrer com o Parceiro, bem como a ocorrência de qualquer um destes eventos:
publicação de pedido ou decretação de falência, protocolo de pedido de recuperação judicial ou
extrajudicial, decretação de intervenção judicial, ocorrência de liquidação judicial ou extrajudicial,
incorporação, fusão e/ou cisão.</p>
      <p>2.6. O Parceiro deve informar imediatamente à WHEY acerca de quaisquer alterações nas informações
fornecidas quando do seu cadastro, devendo mantê-las sempre atualizadas.</p>
      <p>2.7. A WHEY não se responsabiliza pela correção dos dados inseridos pelo Parceiro. Os Parceiros
garantem e respondem, em qualquer caso, civil e criminalmente pela veracidade, exatidão e autenticidade
dos dados cadastrados.</p>
      <p class="clause">III - Modificações Contratuais</p>
      <p>3.1. As condições previstas no Termo Geral e nos Termos de Serviço poderão ser alteradas a qualquer
tempo, e a critério da WHEY, conforme aplicável.</p>
      <p>3.2. As novas condições entrarão em vigor 10 (dez) dias após a comunicação ao Parceiro, veiculada
através do Portal Parceiro, do Site da WHEY, e/ou através do e- mail de cadastro do Parceiro. Em caso
de recusa por parte do Parceiro, o contrato se encerra sem ônus para Partes, sem prejuízo de cumprir as
obrigações pendentes.</p>
      <p>3.3. O Parceiro é exclusivamente responsável por acessar regularmente Whey Admin (Portal Parceiro), o
Site da WHEY, para verificar o Termo vigente e para se assegurar de que os seus itens podem ser
ofertados através do Serviço aplicável, sendo o Parceiro responsável por verificar esses locais e se
informar sobre todas as alterações ou avisos/notificações aplicáveis.</p>
      <p>3.4. O uso subsequente de um Serviço pelo Parceiro, após a entrada em vigor de alguma alteração,
constituirá sua aceitação de tais alterações ou modificações. Entenderá como recusa, a não utilização do
referido serviço.</p>
      <p class="clause">IV. Obrigações das Partes</p>
<p>Da WHEY:</p>
      <p>4.1. A WHEY prestará os serviços constantes nos Termos de Serviço, de acordo com as especificações,
métricas e demais condições constantes nos referidos instrumentos, por meio de técnicas atuais e
profissionais qualificados, assumindo exclusivamente qualquer reivindicação que seus profissionais
possam fazer em face do Parceiro, relativamente à sua condição de empregado ou
terceirizado da WHEY .</p>
      <p>4.2. Do Parceiro:</p>
      <p>4.3. O Parceiro deve defender, indenizar e isentar a WHEY, seus diretores, administradores, 
colaboradores, representantes e empregados de quaisquer responsabilidades, obrigações, danos,
defeitos, prejuízos, reclamações e despesas, diretas e/ou indiretas resultantes, ou decorrentes de: (i)
qualquer ação ou omissão do Parceiro na prestação dos seus serviços ou comercialização de produtos a
seus clientes; (ii) qualquer declaração enganosa, violação de declaração ou garantia ou descumprimento
de qualquer avença ou acordo do Parceiro; ou (iii) qualquer reivindicação com base em alegada violação
pelo Parceiro de quaisquer direitos de terceiros. Ao Parceiro será assegurado o direito de defesa.</p>
      <p>4.4. O Parceiro é o único responsável pelas atividades que ocorrerem em sua conta, devendo guardar sua
senha de acesso em local seguro. O Parceiro deve notificar a WHEY imediatamente sobre qualquer
violação de segurança, perda de senha ou uso não autorizado de sua conta. As informações constantes da
conta do cliente, incluindo suas informações cadastrais, serviços contratados e informações financeiras,
poderão ser acessadas pela WHEY, assim como por prestadores de serviços terceirizados da WHEY, ou,
ainda, a terceiros, em caso de reestruturação societária, devendo o Parceiro ser notificado de tal ato.</p>
      <p>4.5. O Parceiro não poderá contratar empregados ou prestadores de serviço da WHEY que atuem direta
ou indiretamente na prestação de serviços ao Parceiro no âmbito da relação comercial das Partes, nem
poderá iniciar contato com qualquer dos referidos empregados a fim de induzi- los ao término de seu
vínculo com a WHEY durante a vigência deste Termo e pelo período de 12 (doze) meses contados a partir
do término do vínculo empregatício ou contrato de prestação de serviços. A violação desta cláusula
importará ao Parceiro a obrigação do pagamento de multa no valor de 06 (seis) vezes o valor da última
remuneração do respectivo empregado ou prestador de serviços, para cada infração cometida, sem
prejuízo das demais sanções previstas neste Termo e/ou nos Termos de Serviço aplicáveis.</p>
      <p>4.6. O Parceiro não poderá realizar qualquer comunicação direta, física ou digital, por qualquer meio ou
formato, com o cliente final sem a prévia autorização da WHEY, ressalvadas aquelas atinentes à entrega
dos produtos por este adquirido, sob pena de término imediato da relação comercial
entre o Parceiro e a WHEY, a critério exclusivo desta.
      <p class="clause">V. Declarações, Responsabilidades e Indenizações</p>
      <p>5.1. O Parceiro declara possuir plena capacidade e legitimidade para celebrar o Termo Geral e os
respectivos Termos de Serviço. A adesão a estes Termos e o cumprimento das obrigações pelo Parceiro
(i) não violam qualquer disposição contida nos seus documentos societários, quando aplicável; (ii) não
violam qualquer lei, regulamento, decisão judicial, administrativa ou arbitral, aos quais esteja vinculado; (iii)
estão devidamente autorizados, nos termos dos seus atos constitutivos em vigor, quando aplicável; (iv) não
constituem uma violação, nem estão em conflito com qualquer contrato do qual seja parte; (vi) serão válidos
mediante assinatura física ou eletrônica do Parceiro.</p>
      <p>5.2. O Parceiro declara ainda que: (i) Exerce sua atividade em conformidade com toda a legislação e
regulamentação que lhe é aplicável, incluindo, sem limitação, normas de direito do trabalho e previdência
social, propriedade intelectual e industrial e tratamento informático de dados pessoais; (ii) Recebeu todas 
as informações necessárias relativas às funcionalidades e ao conteúdo dos Serviços; (iii) Está ciente e de
pleno acordo com a Ética empregada pela WHEY, que para todos os efeitos, refere- se ao presente
Termo; (iv) Não utiliza trabalho ilegal, e compromete-se a não utilizar práticas de trabalho análogo ao
escravo, ou de mão de obra infantil, salvo este último na condição de aprendiz, observadas às
disposições da Consolidação das Leis do Trabalho, seja direta ou indiretamente, por meio de seus
respectivos fornecedores de produtos e de serviços; (v) compromete-se a proteger e preservar o meio
ambiente, bem como a prevenir e erradicar práticas danosas ao meio ambiente, executando seus serviços
em observância à legislação vigente no que tange à Política Nacional do Meio Ambiente e dos Crimes
Ambientais, bem como dos atos legais, normativos e administrativos relativos à área ambiental e correlatas,
emanados das esferas Federal, Estaduais e Municipais e (vi) está aderente às regras prescritas na Lei Geral
de Proteção de Dados; (vi) não paga e não pagará, tampouco prometerá dar qualquer coisa de valor, a
qualquer título, direta ou indiretamente, a qualquer oficial do governo, membro ou funcionário de qualquer
partido político e/ou pessoa que exerça função pública, com o objetivo de influenciar uma ação ou decisão
do mesmo que possa interferir na obtenção ou retenção de negócios e/ou vantagens para esta Parte, suas
controladoras, controladas, coligadas e/ou quaisquer sociedades a ela ligadas.</p>
      <p>5.3. A WHEY têm o direito, a seu exclusivo critério, orientar e determinar o conteúdo, a aparência, o
design, a funcionalidade e todos os demais aspectos dos Serviços, inclusive, redesenhar, modificar,
remover ou restringir os acessos a qualquer um deles. O Parceiro será comunicado com
antecedência de 10 dias de tais modificações, remoções e restrições.</p>
      <p>5.4. Visando garantir e fomentar a parceria com a disponibilização de oportunidades junto aos parceiros
financeiros da WHEY, o Parceiro desde já autoriza o compartilhamento das informações relacionadas aos
Serviços.</p>
      <p>5.5. Alguns Serviços disponibilizados pela WHEY podem permitir a inserção de conteúdos na plataforma
disponibilizada pela WHEY diretamente pelo Parceiro. A WHEY não fiscalizam os conteúdos inseridos
pelos Parceiros, e, portanto, se
isenta de toda e qualquer responsabilidade por condutas ou conteúdos dos Parceiros.</p>
      <p>5.6. O Parceiro reconhece expressamente que a WHEY não é responsável por nenhum problema técnico
e/ou operacional, incluindo falhas, perda de informação, suspensões, interrupções e qualquer outro mau
desempenho dos serviços, salvo se os Termos de Serviço dispuserem diferentemente.
      <p>5.7. A WHEY não se responsabiliza ainda, por qualquer dano, prejuízo ou perda sofridos pelo Parceiro em
razão de falhas na internet, no sistema ou no servidor, decorrentes de condutas de terceiros, caso fortuito
ou força maior.</p>
      <p>5.8. A WHEY não garante a ausência de vírus ou qualquer outro elemento eletrônico nocivo, que possa
danificar dados, informações ou o funcionamento de outros computadores, impedindo o uso normal da
rede, do sistema ou dos equipamentos de terceiros, não sendo responsável por qualquer vírus que possa
atacar o equipamento do Parceiro em decorrência do acesso, utilização ou navegação na internet, ou 
como consequência da transferência de dados, arquivos, imagens, textos ou áudio, ou ainda, por vícios
ou defeitos técnicos e/ou operacionais oriundos do sistema do Parceiro ou de terceiros. A WHEY isentase de qualquer responsabilidade pelos danos e consequentes prejuízos de qualquer natureza daí
decorrentes.</p>
      <p>5.9. O Parceiro reconhece e concorda que os Serviços oferecidos por meio de plataformas digitais não
estarão isentos de erros, e não há qualquer garantia explícita ou implícita de que será adequado ao objetivo
particular do Parceiro ao utilizá-lo. Ademais, embora a WHEY faça esforços razoáveis para incluir
informações precisas e atualizadas em sua plataforma, a WHEY não garante ou afirma sua precisão e
atualidade, e não se compromete a realizar qualquer backup de informações encaminhadas pelo
Parceiro.</p>
      <p>5.10. Alguns Serviços podem depender da funcionalidade de vários fatores, como a interação de servidores
e serviços de telecomunicações de terceiros, a adequação dos equipamentos do Parceiro e de terceiros,
entre outros. Tendo isso em consideração, a WHEY empregará seus melhores esforços para assegurar
que sua plataforma digital funcione adequadamente, sem interferências. No entanto, a WHEY não garante
que os Serviços estarão disponíveis de forma ininterrupta ou livre de quaisquer erros ou falhas e
tampouco assegura a impossibilidade do seu sistema e servidores poderem ser atacados, invadidos,
modificados ou lesados, de qualquer forma, porterceiros.
      <p>5.11. Em caso de interpelação judicial que tenha como réu a WHEY, cujos fatos fundem-se em ações do
Parceiro, este será chamado ao processo, devendo arcar com todos os ônus que daí decorram, nos
termos do artigo 125, II, do Código de Processo Civil, caso seja cabalmente demonstrada e provada a
culpa do Parceiro.</p>
      <p>5.12. O Parceiro indenizará a WHEY, diretores, administradores, colaboradores, representantes e
empregados por qualquer perda oriunda de demanda administrativa ou judicial promovida por
consumidores ou terceiros decorrentes do descumprimento deste Termo Geral e/ou dos respectivos
Termos de Serviço, e ainda, pela violação de qualquer lei ou direitos de terceiros, incluindo honorários de
advogados. Ao Parceiro será assegurado o direito de defesa e caso seja comprovado o dolo ou culpa do
Parceiro, o mesmo autoriza a WHEY a efetuar a compensação de tais perdas das comissões,
remunerações ou quaisquer outros valores que deva repassar ao Parceiro.</p>
      <p>5.13 O Parceiro autoriza a WHEY a deduzirem dos valores a serem efetivamente repassados ao Parceiro,
todos e quaisquer custos, despesas, condenações, honorários advocatícios, periciais ou custas
processuais relacionadas as referidas reivindicações, comprovadamente suportadas pela WHEY, caso
seja efetivamente comprovada o dolo ou culpa do Parceiro.</p>
      <p>5.14. Para quaisquer quantias devidas pelo Parceiro, à WHEY poderá: (i) faturar quantias devidas pelo
Parceiro à WHEY, hipótese em que o Parceiro se obriga a pagar tais faturas; (ii) estornar quaisquer
créditos do Extrato de Pagamento do Parceiro no Portal Parceiro ou; (iii) cobrar ou pleitear indenização
por qualquer meio legal. Será facultado ao Parceiro o direito de defesa, mas se ficar cabalmente 
demonstrado que o Parceiro utilizou a sua conta em atividades enganosas, fraudulentas ou ilegais, a
WHEY poderá, a seu exclusivo critério, reter permanentemente quaisquer pagamentos ao Parceiro.</p>
      <p>5.15. Em qualquer hipótese, a responsabilidade máxima das Partes, decorrente destes Termos Gerais e
dos Termos de Serviço será limitada aos danos diretos, comprovadamente sofridos pelo Parceiro ou pela
WHEY e exclusivamente em razão de descumprimento das obrigações contraídas pelas Partes, limitadas
ao maior valor pago pelo Parceiro à WHEY sob os Termos Específicos objeto do descumprimento, nos 03
(três) meses antecedentes ao dano.</p>
      <p>5.16. O Parceiro poderá ter direito de ação unicamente em face da WHEY, com relação aos Serviços
prestados pela WHEY.</p>
      <p class="clause">VI - Sanções</p>
      <p>6.1. Sem prejuízo de outras medidas previstas neste Termo Gerais e/ou nos respectivos Termos de Serviço,
a WHEY, notificará previamente o Parceiro, podendo advertir ou suspender a prestação de serviços ao
Parceiro, a qualquer tempo, iniciando as medidas legais cabíveis se: (a) O Parceiro descumprir qualquer
dispositivo deste Termo Geral ou suas obrigações no âmbito da execução dos Termos de Serviço; (b) O
Parceiro praticar atos fraudulentos ou dolosos; (c) Não puder ser verificada a identidade do Parceiro ou
qualquer informação fornecida por ele esteja incorreta; e/ou (d) A WHEY entender que qualquer atitude
do Parceiro tenha causado algum dano a terceiros ou à própria WHEY ou tenham a potencialidade de
assim o fazer. Em quaisquer dos casos referidos, será assegurado ao Parceiro o direito de defesa.</p>
      <p class="clause">VII - Pagamento + Fiscal</p>
      <p>7.1. Os Termos de Serviço irão determinar os valores devidos à WHEY, conforme o caso, pela prestação
dos Serviços. O atraso nos pagamentos devidos por conta dos Serviços contratados nos Termos de
Serviço implicará à Parte inadimplente a aplicação de multa de 2% (dois por cento), juros de 1% (um por
cento) ao mês e correção monetária, de acordo com a variação do IGPM/FGV, ou, em sua falta, do
IPC/FIPE ou outro índice que venha a substituí-lo.</p>
      <p>7.2. O Parceiro autoriza a WHEY a reter dos repasses eventualmente previstos os valores em atraso
relacionados à prestação dos Serviços pela WHEY. Realizada referida retenção, esta corresponderá à
automática quitação em favor doParceiro.</p>
      <p>7.3. O Parceiro será responsável por quaisquer despesas razoavelmente incorridas pela WHEY em razão
da cobrança de valores não quitados quando devidos, incluindo honorários advocatícios.</p>
      <p class="clause">VIII - Prazo e Rescisão</p>
      <p>8.1. Esse Termo Geral passa a vigorar a partir da assinatura ou aceite digital do Parceiro, e terá vigência
até a data de término do último Termo de Serviçovigente.</p>
      <p>8.2. Sem prejuízo de disposições específicas previstas nos Termos de Serviço, esse Termo Geral 
poderá ser rescindido a qualquer tempo, pela WHEY, com comunicação prévia em caso de: (i) infração
pela outra Parte, de quaisquer disposições constantes do Termo Geral ou dos respectivos Termos de
Serviço; (ii) insolvência, deferimento de pedido de falência ou de recuperação judicial ou extrajudicial do
Parceiro; (iv) descrédito comercial, ou violação de direitos de terceiros, incluindo, sem limitar, os
consumidores dos Parceiros. Nessas hipóteses, todos os Termos de Serviço serão
automaticamente rescindidos. Fica facultado ao Parceiro o direito de defesa nas hipóteses acima
referidas.</p>
      <p>8.3. Caso o Parceiro deseje rescindir este Termo Geral ou cancelar a assinatura de qualquer dos Serviços,
imotivadamente, poderá encaminhar uma notificação à outra Parte, com 30 (trinta) dias de antecedência,
informando o seu desejo de encerrar a relação ora estabelecida, ressalvadas as condições específicas
estabelecidas em cada Termo de Serviço, após o cumprimento de todas as obrigações junto a WHEY,
aos clientes e a terceiros relacionados ao objeto do presenteTermo.</p>
      <p>8.4. Em qualquer hipótese de rescisão deste Termo Geral, todos os Termos de Serviço serão
automaticamente rescindidos.</p>
      <p class="clause">IX - Política de Segurança</p>
      <p>9.1. Todos os dados informados pelo Parceiro são armazenados em servidores (AMAZON) ou outros meios
magnéticos seguros, de acordo com práticas usuais de mercado. Sem prejuízo do disposto, o Parceiro
reconhece e concorda que, apesar da WHEY tomar todas as medidas possíveis para
manter a confidencialidade e a segurança dos dados do Parceiro, a WHEY não
responderá por quaisquer prejuízos eventualmente decorrentes da violação dessas medidas por parte de
terceiros, subvertendo assim os sistemas de segurança da plataforma contratada pela WHEY, exceto nos
casos diretamente decorrentes de culpa ou dolo por parte da WHEY . O Parceiro expressamente autoriza,
desde que previamente comunicado, que suas informações e dados cadastrais sejam compartilhados
pela WHEY, parceiros comerciais, autoridades governamentais e judiciais e pessoas físicas ou jurídicas
que aleguem terem sido lesadas pelo Parceiro.</p>
      <p class="clause">X- Confidencialidade</p>
      <p>10.1. As Partes comprometem-se a tratar todas e quaisquer informações confidenciais relativas aos
Serviços objetos deste Termo Geral e dos respectivos Termos de Serviço, em caráter estritamente
confidencial, responsabilizando-se por todas e quaisquer perdas, danos e/ou prejuízos incorridos pelas
Partes, em decorrência deste inadimplemento.</p>
      <p>10.2 - Entende-se por “Informações Confidenciais” todas e quaisquer informações e dados de natureza
confidencial e incluindo, sem limitação, todos os segredos e/ou informações financeiras, operacionais,
econômicas, técnicas, de engenharia ou programação, bem como todas as cláusulas contratuais oriundas 
deste contrato e as demais informações comerciais ou know-how e quaisquer cópias ou registros dos
mesmos, orais e escritos, expressamente identificados como sendo informações confidenciais, contidos
em qualquer meio offline e online, que tenham sido direta ou indiretamente revelados pela WHEY ou de
qualquer forma obtidos ou acessados sob ou em função deste Contrato (inclusive durante as
negociações precedentes à assinatura do mesmo).</p>
      <p>10.3 - Informações Confidenciais não incluem informações: (i) que sejam ou venham a se tornar de domínio
público sem violação do disposto neste Contrato; (ii) que sejam de conhecimento do Parceiro à época de
sua divulgação, desde que o Parceiro possa provar ter tomado conhecimento das mesmas anteriormente
à data de sua divulgação pela WHEY; (iii) recebidas de boa fé pelo Parceiro através de terceiros que
tenham o direito de divulgá-las; (iv) cuja livre divulgação tenha sido autorizada por escrito pela WHEY, ou
(v) desenvolvidas independentemente, sem a utilização de informações que tenham sido divulgadas pela
WHEY em violação das obrigações contidas neste Termo Geral.</p>
      <p>10.4. Todas e quaisquer informações confidenciais que tenham sido transmitidas de uma Parte à outra, e
que sejam identificadas como confidenciais ou cuja natureza seja de confidencialidade evidente, incluindo
todas as condições comerciais negociadas entre as Partes, deverão ser tratadas de modo sigiloso pela
Parte receptora, que deverá utilizar as informações unicamente para desempenhar suas obrigações sob
os Termos Gerais ou Termos de Serviço, devendo manter essas informações protegidas durante a vigência
desses Termos Gerais e pelo período subsequente de 5 (cinco) anos. A Parte receptora das informações
confidenciais deverá restituir essas informações à Parte reveladora, ao término da relação entre as Partes.</p>
      <p>10.5 - Vindo a ser requisitada a revelação de alguma informação confidencial por algum órgão público, o
Parceiro deverá notificar a WHEY, prontamente e por escrito, de modo que esta possa implementar as
medidas cabíveis. Caso as referidas medidas não possam ser implementadas em tempo hábil, o Parceiro
se compromete a revelar apenas o trecho da informação confidencial que for expressa e legalmente
requisitada.</p>
      <p>10.6 – O Parceiro se compromete a somente permitir o acesso à Informação confidencial aos seus
empregados, representantes e às demais pessoas cujo acesso seja necessário para o cumprimento dos
objetivos do presente Termo Geral e/ou dos respectivos Termos de Serviço, sendo vedada sua revelação,
cópia, cessão ou transferência para quaisquer outras pessoas. Caberá ao Parceiro zelar para que seus
empregados, prepostos, colaboradores e/ou quaisquer terceiros com quem compartilhem quaisquer
informações confidenciais cumpram com as obrigações de confidencialidade, respondendo solidariamente
com estes na hipótese de descumprimento.</p>
        <p class="clause">XI - Propriedade Intelectual</p>
      <p>11.1. O Parceiro reconhece e concorda que toda a Propriedade Intelectual utilizada, relacionada ou
resultante dos Serviços é de propriedade ou está licenciada à WHEY. “Propriedade Intelectual” significa:
(a) marcas (registradas ou não), nomes comerciais, nomes de domínio e outros nomes que identificam a
WHEY e seus serviços; (b) patentes, invenções patenteáveis, descobertas, aprimoramentos, ideias,
know- how, metodologia de fórmulas, processos, algoritmos, tecnologias e programas de computador 
(incluindo código fonte e código objeto); (c) segredos comerciais e informações confidenciais;(d) direitos
autorais, projetos, direitos de criação ou outros trabalhos, registros ou solicitações de registro de direitos
autorais em qualquer jurisdição; (e) direitos de base de dados.</p>
      <p>11.2. O Parceiro concorda em não utilizar, reivindicar, reproduzir, duplicar, copiar, vender, revender,
explorar, sublicenciar ou transferir, com ou sem finalidade comercial, qualquer parte da Propriedade
Intelectual, sendo vedada a reprodução, distribuição, transformação, comercialização ou modificação da
Propriedade Intelectual sem a prévia e expressa autorização da WHEY. Quaisquer direitos não
expressamente concedidos sob o presente instrumento sãoreservados.</p>
      <p>11.3. O presente Termo Geral não implica na autorização automática de utilização pelo Parceiro deoutros
programas ou softwares de propriedade da WHEY, diferentes daqueles efetivamente contratados, sendo
certo que cada Serviço deverá ser contratado individualmente, mediante a aceitação de Termo de Serviço
específico para esse fim.</p>
      <p>11.4. A WHEY garante que os Serviços prestados não violam qualquer direito de Propriedade Intelectual
de terceiros, comprometendo-se a manter o Parceiro isento de qualquer reivindicação de terceiros nesse
sentido.</p>
      <p>11.5.O Parceiro concede à WHEY, conforme o caso e pelo prazo de vigência do respectivo Termo de
Serviço, autorização para reproduzir a marca registrada do Parceiro (“Marca”) em todos os meios
escritos, visuais, informáticos e/ou sonoros (incluindo, mas não se limitando, para fins publicitários), em
todo o território brasileiro, conforme necessário à execução dos respectivos Serviços. A utilização da
Marca pela WHEY para quaisquer outros fins dependerá de aprovação prévia e por escrito do Parceiro.</p>
      <p class="clause">XII - Disposições Gerais</p>
      <p>12.1. Este Termo Geral e os respectivos Termos de Serviço são aceitos pelo Parceiro em caráter não
exclusivo, ficando a WHEY, portanto, expressamente autorizada a realizar quaisquer outras parcerias de
mesma natureza com terceiros.</p>
      <p>12.2. Aplica-se ao presente Termo Geral e aos respectivos Termos de Serviços firmados entre as partes a
legislação pertinente e em vigor no território brasileiro, inclusive as normas regulamentadoras do
INMETRO/IPEM, ABNT, ANVISA, ANATEL dentre outras, inclusive, as normas referentes à venda de
produtos importados.</p>
      <p>12.3. A omissão ou tolerância da WHEY em exigir o estrito cumprimento deste Termo Geral não constituirá
novação ou renúncia, nem afetará os seus direitos, que poderão ser exercidos a qualquer tempo.</p>
      <p>12.4. Este Termo Geral e os respectivos Termos de Serviço não importam na criação de qualquer acordo
operacional, joint venture, associação, vínculo societário, de representação, mandato, franquia ou
consórcio entre as Partes, seus sócios, afiliadas, controladas e/ou respectivos funcionários, sendo
expressamente excluídas quaisquer presunções de solidariedade ou subsidiariedade, ativa ou passiva,
entre as Partes, no cumprimento de suas obrigações. Fica, portanto, desde já, expressamente reconhecido
serem as Partes entidades independentes entre si.</p>
      <p>12.5. Este Termo Geral e os respectivos Termos de Serviço também não estabelecem qualquer vínculo
empregatício ou responsabilidade por uma das Partes, seja solidária ou subsidiaria, com relação aos
empregados, prepostos ou terceiros que a outra Parte designar para o cumprimento de suas obrigações,
correndo por conta exclusiva da respectiva Parte, todas as despesas com esses empregados, prepostos
ou terceiros, inclusive encargos decorrentes da legislação vigente, seja trabalhista, previdenciária,
securitária ou qualquer outra.</p>
      <p>12.6. As Partes são contratantes independentes, e nenhuma delas poderá responder por dívidas, tributos,
obrigações ou insucesso comercial da outra Parte. Nenhuma das Partes deverá ser responsabilizada
perante a outra em decorrência de caso fortuito ou evento de forçamaior.</p>
      <p>12.7. O Parceiro não poderá ceder ou transferir a qualquer terceiro, total ou parcialmente, seus direitos e
obrigações decorrentes neste Termo Geral ou dos Termos de Serviço. A WHEY, por sua vez, poderá
ceder quaisquer Termos de Serviços para outra empresa de seu grupo econômico,
mediante notificação ao Parceiro.</p>
      <p>12.8. Se qualquer disposição deste Termo Geral for considerada ilegal, inválida ou, por qualquer razão,
inexequível, tal disposição será considerada separável e não afetará a validade e exequibilidade de
quaisquer disposições remanescentes.</p>
      <p>12.9. Qualquer notificação relacionada a esses Termos Gerais ou aos Termos de Serviço será realizada
através dos endereços eletrônicos cadastrados pelo Parceiro, cabendo exclusivamente ao Parceiro o
acesso a estes endereços eletrônicos, a fim de verificar todos os avisos/notificações aplicáveis.</p>
      <p>12.10. Todos os itens deste Termo Geral e dos respectivos Termos de Serviço são regidos pelas leis
vigentes na República Federativa do Brasil. Para dirimir todos os litígios decorrentes da interpretação ou
cumprimento relacionado a este Termos e seus anexos, fica eleito o Foro da Comarca de São Paulo - SP.</p>


      <p class="title">TERMO DE SERVIÇO – MARKETPLACE</p>
      <p>Este Termo de Serviço descreve os termos e condições aplicáveis ao uso dos serviços oferecidos pela
AMERICAN STORE DISTRIBUIÇÃO DE SUPLEMENTOS EIRELI, CNPJ 18.744.639/0001-47 para a
venda de produtos e serviços no Marketplace, doravante denominado WHEY e outro lado PARCEIRO,
qualificado no Termo de Adesão a este termo de serviços.</p>

      <p>Este Termo de Serviço está vinculado ao Termo Geral. Mediante a aceitação do respectivo Termo Geral e
contratação do serviço Marketplace, o Parceiro aceita as políticas e regras aqui descritas.</p>
      <p>A ACEITAÇÃO DO TERMO GERAL E DESTE TERMO DE SERVIÇO É ABSOLUTAMENTE
INDISPENSÁVEL À UTILIZAÇÃO DOS SERVIÇOS PRESTADOS PELA WHEY.</p>

      <p class="clause">I. Objeto:</p>
      <p>1.1. Os serviços objeto do presente Termo de Serviço dividem-se em:</p>
      <p>(a) Serviços de Marketplace:</p>
      <p>- Intermediação de negócios.</p>
      <p>(b) Serviços de tecnologia (sendo que um ou mais dos serviços listados abaixo poderão ser prestados pela
WHEY ao Parceiro no âmbito deste Termo deServiço):</p>
      <p>- Análise e desenvolvimento de sistemas exclusivos para gestão do Parceiro, incluindo, mas não
se restringindo, ao cadastro dos produtos, processamento e fluxo de pedidos, de nota fiscal, controle
de estoques, cadastro de transportadora, etc.</p>
      <p>- Planejamento, confecção, manutenção e atualização da plataforma e de páginaseletrônicas.</p>
      <p>- Processamento e transmissão de dados em geral através de integração tecnológica (application
programming interface – “API”).</p>
      <p>1.2. A WHEY disponibiliza a estrutura necessária para que o Parceiro ofereça seus produtos por meio de
website registrado e administrado pela WHEY, sem participar no controle de estoque, cadastramento de
produtos, produção e qualidade, não sendo, portanto, fornecedora de quaisquer produtos
anunciados por seus Parceiros.</p>
       <p class="clause">II. Produtos Anunciados:</p>
      <p>a. O Parceiro será responsável por todos os produtos anunciados no Website da WHEY, devendo
constar todas as informações referentes aos produtos, tais como, mas não se limitando, as imagens,
fotos, características, especificações, no formato estabelecido e de acordo com as regras e 
especificações informadas pela WHEY.</p>
      <p>I - O Parceiro deve descrevê-las com clareza, devendo incluir todas as informações exigidas
pela legislação e/ou pela WHEY e, sempre que possível, gráficos, textos, descrições, fotos e
outras informações que possam auxiliar na correta identificação doproduto.</p>
      <p>II - O Parceiro será responsável pelas imagens, características e especificações do produtoque
forem disponibilizadas na plataforma da WHEY.</p>
      <p>III - O cadastro de um produto criado pelo Parceiro no Website WHEY, incluindo, mas não se
limitando à sua foto e especificações, poderá ser utilizado pela WHEY para promoção de
marketing e divulgação da marca eprodutos,</p>
      <p>A WHEY poderá, a seu exclusivo critério, aprovar ou reprovar o cadastro do produto realizado
pelo Parceiro, após comunicado ao Parceiro com justificativa.</p>
      <p>b. O Parceiro deve, em cumprimento à legislação brasileira vigente, além de demonstrar informações
claras e ostensivas a respeito de quaisquer restrições à aquisição do produto, apontar sempre em seus
anúncios as características essenciais do produto, incluindo os riscos à saúde e à segurança dos
consumidores, quando aplicável.</p>
      <p>c. Presumir-se-á que, mediante o cadastramento do produto na plataforma da WHEY, o Parceiro
manifesta a intenção e declara possuir o direito de vender o produto oferecido, além de dispor do produto
para entrega ao consumidor. O Parceiro expressamente autoriza que suas informações cadastrais sejam
compartilhadas pela WHEY no Website WHEY juntamente com o produto ofertado.</p>
      <p>d. Não é permitido anunciar produtos expressamente proibidos pela legislação vigente, que não possuam
a devida autorização específica de órgãos reguladores competentes, que violem direitos de terceiros, ou
ainda para os quais o Parceiro não esteja autorizado por seus cadastrosfiscais.</p>
      <p>e. Não é permitido inserir ou manter no próprio anúncio, produto ou entrega, quaisquer dados pessoais
para contato, tais como, mas não se limitando a, números de telefones, endereços de e-mails, ou páginas
de internet que contenham os dados supracitados, nem fazer publicidade de outros meios depagamentos
que não sejam aqueles expressamente disponibilizados no Website WHEY.</p>
      <p>f. A WHEY será responsável pelo posicionamento dos produtos anunciados em seu Website, de acordo
com o raciocínio e necessidades comerciais definidos pela equipe de Marketing da WHEY.</p>
      <p>g. A WHEY poderá, a seu exclusivo critério, recusar-se a incluir quaisquer ofertas de produto em seu
Website, bem como solicitar confirmação ao Parceiro quanto às informações fornecidas. A WHEY poderá,
ainda, editar o anúncio ou solicitar que o Parceiro o faça, ou remover o anúncio do produto em seu
Website, desde que haja comunicação prévia ao Parceiro, sempre que houver violação de algum dos
dispositivos do Termo Geral, deste Termo de Serviço ou das demais políticas da WHEY.</p>
      <p>h. O apelido do Parceiro, juntamente com sua logomarca, deverá acompanhar os produtos por ele
anunciados no Website WHEY , e não poderá guardar semelhança com o nome da AMERICAN STORE
DISTRIBUIDORA DE SUPLEMENTOS EIRELI doravante WHEY, bem como nomes de domínio ou outro
bem de propriedade intelectual, tampouco poderá ser utilizado qualquer apelido que insinue ou sugira que
os produtos/serviços anunciados pertencem ou tenham qualquer relação com a WHEY. Também serão
excluídos apelidos considerados ofensivos, bem como os que contenham quaisquer dados do Parceiro,
alguma URL ou endereço eletrônico.</p>
      <p>Preço e Estoque:</p>
      <p>i. O Parceiro deve informar à WHEY a quantidade de produtos que deseja vender e o preço por
unidade. Os dados referentes ao estoque deverão ser mantidos atualizados pelo Parceiro.</p>
      <p>j. O preço por unidade informado pelo Parceiro nos produtos anunciados no Website WHEY
deverá ser o mesmo, ou menor, que aquele praticado em seus websites e/ou quaisquer outras
plataformas de vendas, assim como o valor do frete, sob pena de não priorização da oferta do
Parceiro.</p>
      <p>k. O Parceiro será o único responsável por determinar o preço dos produtos por ele ofertados noWebsite
WHEY, sendo que o parcelamento das vendas seguirá as regras praticadas pela WHEY em seuWebsite.</p>
      <p class="clause">III. Venda:</p>
      <p>a. . A WHEY será responsável por administrar as vendas online, levando em conta análises de crédito
dos consumidores.</p>
      <p>b. A WHEY será responsável por estabelecer as condições de pagamento, bem como realizar a
cobrança dos consumidores.</p>
      <p>c. A WHEY disponibilizará ao Parceiro as informações referentes aos pedidos efetuados, via Conta
SELLER, sempre que alguma compra do produto por ele ofertado for confirmada, inclusive os dados
necessários para emissão de Nota Fiscal ao consumidor, a qual deverá ser obrigatoriamente emitida
pelo Parceiro, em conformidade com a legislação vigente, em momento anterior ao envio do produto, e
entregue ao consumidor, sob pena de descredenciamento do Parceiro e/ou retenção dos valores que este
tenha a receber no âmbito deste Termo de Serviço, até que a emissão da Nota Fiscal seja regularizada,
sem prejuízo de eventuais sanções legais aplicáveis.</p>
      <p>d. O Parceiro deverá informar à WHEY todas as atualizações que estejam sob sua responsabilidade
referentes ao status dos pedidos, tais como, mas não se limitando a, emissão de nota fiscal, produto
entregue à transportadora, código de rastreio da entrega, pedido em trânsito entre centros de distribuição,
extravio, insucesso de entrega, pedido em rota de entrega, pedido entregue ao cliente, sob pena de
cancelamento do respectivo pedido ou a tomada de qualquer outra providência razoável pela WHEY, a fim
de corrigir eventuais problemas e/ou reclamações relativos aos pedidos em questão.</p>
      <p>e. Para efeito do disposto acima, o Parceiro deverá ainda, manter a guarda dos comprovantes
relacionados às operações de compra e venda e entrega de mercadorias ao consumidor final, sob sua
responsabilidade, pelo período fixado na legislação aplicável.</p>
      <p class="clause">IV. Entrega:</p>
      <p>a. Deverá o Parceiro garantir que os produtos vendidos no Website WHEY observem, no momento da
entrega, seus respectivos prazos de validade e estejam de acordo com as indicações constantes da
embalagem, rotulagem e de todo o material de comunicação, bem como que estejam embalados
adequadamente, sem qualquer indício de deterioração, obrigando-se pelo recebimento em devolução
daqueles itens onde seja constatada alguma irregularidade e posterior ressarcimento do valor do produto
devolvido.</p>
      <p>b. As entregas deverão ser enviadas pelo Parceiro após a confirmação do pagamento pela plataforma
Mercado PAgo e terá o Parceiro o mesmo prazo para responder chamados feitos pelos consumidores e
repassados ao parceiro pela WHEY, sob pena de retenção do repasse dos valores das vendas que estão
em análise até a solução do problema, o qual deverá ser solucionado, exclusivamente, pelo Parceiro.</p>
      <p class="clause">V. Pós Venda:</p>
      <p>a. O Parceiro reconhece e aceita que os produtos anunciados no Website WHEY o vinculam, na
medida em que atua como um fornecedor de produtos, nos termos do artigo 30 do Código de Defesa do
Consumidor e do artigo 429 do Código Civil, cujo cumprimento pode ser exigido judicialmente pelo
consumidor.</p>
      <p>b. O Parceiro será responsável por realizar o cancelamento de venda dos produtos que venham a
apresentar defeitos e/ou vícios, bem como realizar cancelamentos por arrependimento, sempre nos termos
do Código de Defesa do Consumidor e legislação aplicável.</p>
      <p>c. A WHEY disponibilizará sua plataforma de atendimento ao consumidor, através de e-mail ou chat
(“SAC WHEY), para recepção e transferência dos contatos relativos às compras efetuadas em seu
Website, devendo o Parceiro utilizá-la para os contatos relacionados às operações realizadas no Website
WHEY, vedada a utilização de outros canais estranhos ao SAC WHEY para essa finalidade. Sendo a
WHEY demandada atravésde seu SAC ou através de reclamação proposta administrativa ou
judicialmente , por ou perante órgãos de proteção do consumidor, em razão de vícios de produto e/ou
falhas da prestação de serviços atribuíveis ao Parceiro, contatará o Parceiro para que solucione a
reclamação, devendo o Parceiro manter a WHEY isenta de qualquer responsabilidade pelos ônus
decorrentes das reclamações ou da demora na solução.</p>
      <p>d. Sendo a WHEY demandada judicialmente, por conta de falhas na prestação de serviços atribuíveis
ao Parceiro, poderá efetuar a retenção do repasse dos valores, oriundo da demanda, devidos ao Parceiro,
até o encerramento da respectiva demandajudicial.</p>
      <p class="clause">VI. Remuneração:</p>
      <p>a. O Parceiro remunerará a WHEY pelos Serviços com o pagamento de comissão incidente sobre o
valor do total da venda (entenda-se: valor do produto + frete )</p>
      <p>i. O Parceiro receberá, via sistema Mercado Pago, o valor correspondente à venda do produto por
ele anunciado, deduzido o comissionamento, conforme condições especificadas no Anexo de
Remuneração vigente. Caberá ao Parceiro disponibilizar à WHEY a comprovação de que os pedidos
foram devidamente enviados e entregues.</p>
      <p>ii.O comissionamento acordado no Anexo de Remuneração vigente deverá ser paga à WHEY também
nos casos em que a entrega do produto não se concretizar por culpa do Parceiro, cancelamento da
compra por Culpa do Parceiro ou devolvida por qualquer motivo, bem como em casos de chargeback
( ocorre quando o consumidor solicita o cancelamento da compra diretamente a operadora do cartão
) pela operadora do cartão.</p>
      <p>iii.O comissionamento pago pelo Parceiro contempla as vendas efetuadas nas modalidades de cartão de
crédito e boleto bancário ou retirda na loja, praticadas pela WHEY em seuWebsite.</p>
      <p>iv. O Parceiro declara-se ciente de que as ferramentas de gestão de pagamentos disponibilizadas pela
WHEY em seu website/plataforma não são e nem se destinam a ser comparáveis aos serviços
financeiros oferecidos por instituições bancárias ou administradoras de cartão de crédito, consistindo 
apenas em uma forma de facilitação e acompanhamento da realização de transações para
pagamentos e recebimentos entre as partes. Ressalta-se ainda, que a gestão de pagamentos
disponiveis poderão ser modificados, a qualquer tempo, sem que haja a necessidade de aviso prévio
ao Parceiro.</p>
      <p>b. O Parceiro obterá uma conta digital através do Mercado PAgo a qual será de acesso restrito ao
Parceiro. Esta conta digital será a responsavel por creditar os valores a serem recebidos pelo Parceiro. A
WHEY, adverte que os valores devidos aos Parceiros possuem um repasse automático através do
Mercado Pago, não possuindo acesso ou contato com tais valores. A WHEY, trabalha com a
funcionalidade SPLIT, ou seja, os valores devidos são creditados na conta digital de cada SELLER,
acrescidos do frete (caso haja frete incluso no pedido) já subtraídos o valor do comissionamento
acordado, conforme Anexo de Remuneração.</p>
      <p>c. O crédito ocorrerá na modalidade D+14 e poderá ser visualizado na conta digital do Parceiro, e
implicará na quitação automática das vendas efetuadas no website WHEY e recebidas, via sistema
Mercado Pago.</p>
      <p>d. Não obstante o disposto acima, o Parceiro terá o prazo de até 60 (sessenta) dias a contar da data da
realização do repasse pela WHEY, para apontar qualquer divergência em relação aos valores pagos.
Findo tal prazo, não caberá qualquer reclamação por parte do Parceiro e ocorrerá a quitação
automática, irrevogável, irretratável e definitiva quanto aos referidosvalores.</p>
      <p class="clause">VII. Obrigações Tributárias:</p>
      <p>a. É de inteira responsabilidade do Parceiro o recolhimento do ICMS devido nas operações de compra e
venda de mercadorias, na forma da legislação vigente, bem como de todos os tributos incidentes e/ou
decorrentes de suas próprias atividades.</p>
      <p>b. A WHEY emitirá Nota Fiscal de prestação de serviço mensalmente, referente a remuneração, a favor
do Parceiro, dando quitação do comissionamento.
Penalidades:</p>
      <p>c. Caso as solicitações disponibilizados no Portal Parceiro não sejam cumpridos para determinado
pedido, e o Parceiro não retorne as solicitações do usuário Marketplace e/ou da WHEY no prazo
estabelecido ( de 24 horas ), a WHEY tem a prerrogativa de cancelar ou tomar qualquer providência
razoável para sanar o problema relacionado ao respectivo pedido, às expensas do Parceiro, mediante
retenção do respectivo valor no repasse previsto ao Parceiro e sem prejuízo do pagamento da comissão
correspondente.</p>
      <p>d. Nos casos de inabilitação do cadastro do Parceiro, todos os produtos anunciados e/ou ofertas
realizadas no Website WHEY serão automaticamente cancelados. São casos de inabilitação, incluindo,
mas não se limitando a (i) comercialização de produtos com violação dos direitos de propriedade
intelectual; (ii) comercialização de produtos importados que não tenham sido regularmente internalizados
em território nacional; (iii) comercialização de produtos que não estejam autorizados pelos cadastros fiscais
do Parceiro; (iv) falta de emissão de notas fiscais das mercadoriascomercializadas.</p>
      <p class="clause">VIII. Rescisão</p>
      <p>a. Este Termo de Serviço poderá ser rescindido imotivadamente, por qualquer uma das Partes,
mediante aviso prévio de 30 (trinta) dias.</p>
      <p>i.a. indisponibilidade total de estoque do Parceiro por um período superior a 30 (trinta)
dias consecutivos configurará a rescisão unilateral do presente Termo de Serviço.</p>
      <p>b. Subsistirão, após a rescisão deste Termo de Serviço, as obrigações do Parceiro relacionadas às
operações realizadas durante a sua vigência, incluindo, mas não se limitando a: (i) de pagamento de débitos
de qualquer natureza contraídos junto à WHEY; (ii) de entrega dos produtos vendidos;
(iii) de ressarcimento dos valores de produtos que apresentem defeitos no prazo de garantia; (iv) de
atendimento dos clientes que apresentem dúvidas, tenham sugestões ou reclamações relacionadas aos
produtos, prazos de entrega, etc.; (v) de fornecimento de subsídios para que a WHEY apresente sua própria
defesa nas demandas relacionadas às relações de consumo estabelecidas com o Parceiro; (vi) de
pagamento das indenizações judiciais e honorários advocatícios que decorram de ações judiciais contra o
Parceiro, contra a WHEY ou contra o Parceiro e a WHEY em razão das operações realizadas no
Marketplace, desde que comprovada a culpa ou dolo do Parceiro. Restando demonstrado o dolo ou culpa
por parte da WHEY, esta deverá arcar o pagamento das indenizações judiciais e honorários
advocatícios que decorram de tais
ações judiciais. Após a rescisão, a fim de garantir o cumprimento das obrigações do Parceiro que
subsistem à vigência deste Termo de Serviço, a WHEY poderá reter ou fazer a devida cobrança ao
Parceiro, a seu exclusivo critério, dos valores correspondentes aos pedidos com chamado em aberto
junto ao SAC. Á medida que os respectivos casos forem solucionados, os valores serão
proporcionalmente liberados ou restituídos ao Parceiro.</p>
      <p>c. Subsiste, em relação à WHEY, a obrigação de pagamentos dos repasses devidos em razão da
comercialização dos produtos ao Parceiro, deduzidos os descontos previstos neste Termo de Serviço.
.</p>

      <p class="title">ANEXO - REMUNERAÇÃO</p>
      <p>A WHEY repassará, via sistema Mercado Pago, ao Parceiro o valor correspondente à
venda do produto por ele anunciado, conforme Proposta Comercial vigente:</p>
      <p>1. Datas de Repasse:</p>
      <p>I. Os repasses referentes às vendas concretizadas, pagas e entregues ocorrerão D+14 para
vendas realizadas via cartão de crédito e D+2 para vendas realizada no boleto bancário, de
forma automatica, que poderão ser visualizadass e manuseada em sua conta digital. A conta
digital é de acesso restrito do Parceiro.</p>
      <p>II. O valor do frete também será repassado de forma automática e proporcional ao peso e
tamanho, pela faixa de CEP do produto anunciado pelo Parceiro no website WHEY.
a. A comprovação de que os pedidos foram enviados à transportadora
será realizada, obrigatoriamente, mediante o envio dos códigos de rastreio
da entrega. O referido envio se dará única e exclusivamente através do
sistema administrativo da conta do Parceiro.</p>
      <p>III. Regras de Liberação:</p>
      <p>a. Por se tratar de um sistema de repasse automático, o pedido deve ter sua
entrega confirmada , sob pena de retenção do repasse do respetivo valor devido
ao Parceiro, que deverá demonstrar o comprovante daentrega.</p>
      <p>b. Na hipótese de não envio dos códigos de rastreio por parte do Parceiro, a
WHEY não terá nenhum tipo de responsabilidade pela retenção acima referida.</p>
      <p>c. Após resolução dos casos onde os pedidos estiverem com Status de Entrega
(ENT) Pendente, os valores de repasse dos respectivos serão regularizados.</p>
      <p>d. A confirmação do pagamento da venda é o fato gerador do comissionamento,
de forma que, nos termos do Termo de Condições Gerais e Termo de Serviço, o
comissionamento será devido mesmo em caso de cancelamento, ou devolução do
pedido desde que comprovado a culpa do Parceiro, podendo o referido
comissionamento, contudo, ser abonado a exclusivo critério da WHEY, sem que
isso seja caracterizado novação do Termo de Condições Gerais e do Termo de
Serviço, ou, ainda, das condições de remuneração aqui
previstas.</p>
      <p>e. Em caso de cancelamento ou devolução que reste comprovada a culpa do
Parceiro, a cobrança do comissinamento será feito através da emissão de boleto
bancário. Será enviado mensalmente relação dos pedidos devolvidos, ou
cancelados e posterior emissão do boleto, para pagamento da comissão devida. O
não pagamento ensejará a retenção dos valores de repasse ao Parceiro até que
sejam efetivamente pago o valor devido a título de comissão.</p>
      <p>IV. Comissão</p>
      <p>A WHEY será remunerada no importe de 18% (dezoito), a título de comissionamento
(sobre o valor do produto), pelos serviços prestados, tais como mas não se limitando, (i) a
planejamento de marketing, (ii) divulgação em midias onlines e redes socias, (iii) intermediação de
negócios, (iv) por serviços de tecnologia, planejamento, confecção, manutenção e atualização da
plataforma e de páginas eletrônicas e etc.</p>
    </b-modal>
  </div>
</template>

<style lang="scss">
  p {
    &.clause {
      font-weight: 500;
      text-decoration: underline;
    }

    &.bold {
      font-weight: bold;
    }
  }

  .terms {
    display: flex;
    justify-content: center;

    p {
      span {
        text-decoration: underline;
        font-weight: 500;
        cursor: pointer;
      }
    }
  }

  .confirmation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .img-login {
      margin-top: 0px;
      margin-bottom: 20px;
    }

    i {
      font-size: 50px;
      margin-bottom: 20px;
    }

    p.title {
      font-size: 20px;
      font-weight: 500;
    }
  }
</style>